<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.autoConfirm') }}</h3>
    <toggle v-if="autoconfirm" :key="autoconfirm" :data="autoconfirm" :onChange="function (data) { changeAutoconfirm(data) }">
      <template v-slot:0>{{ $t('message.no') }}</template>
      <template v-slot:1>{{ $t('message.yes') }}</template>
    </toggle>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
      autoconfirm: false
    }
  },
  methods: {
    getAutoconfirm () {
      if (this.data.autoconfirm) {
        this.autoconfirm = this.$t('message.yes')
      } else {
        this.autoconfirm = this.$t('message.no')
      }
    },
    changeAutoconfirm (data) {
      if (this.autoconfirm === this.$t('message.yes')) {
        this.autoconfirm = this.$t('message.no')
        this.callback(false)
      } else {
        this.autoconfirm = this.$t('message.yes')
        this.callback(true)
      }
    }
  },
  mounted () {
    this.getAutoconfirm()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
